import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


//props:-
//color: true -> pink else blue
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    width:"70px",
    position: "relative",
    left: "50%",
    top: "50%"
  },
}));

export default function Loader(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress 
      color={props.color ? "secondary" : "primary" }
      size={70}
      
      />
    </div>
  );
}
